import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
  max-width: ${props => (props.maxWidth || "100%")};
  height: auto;
`;

const SetImage = ({ src, maxWidth }) => {
  const imageRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(null);

  useEffect(() => {
    if (imageRef.current) {
      const parentHeight = imageRef.current.parentElement.clientHeight;
      setParentHeight(parentHeight);
    }
  }, [src]);

  return (
    <ImageContainer>
      <Image
        src={src}
        alt="Image Alt Text"
        maxWidth={maxWidth}
        style={{ maxHeight: parentHeight }}
        ref={imageRef}
      />
    </ImageContainer>
  );
};

export default SetImage;
