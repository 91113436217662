import React from "react";
import styled from "styled-components";
import * as Common from "../components/index";
import { colors } from "../lib/Colors";

const OperatingEnvironmentText = styled.div`
  font-weight: ${(props) => props.textweight || "normal"};
  color: ${(props) => props.fontcolor || "black"};
  text-align: left;
  padding: 1vw;
  font-size: 3vw;
`;

const OperatingEnvironmentContainer = styled.div`
  display: inline-block;
  width: 80%;
  min-height: 15vw;
  text-decoration: none;
  position: relative;
  margin: 0px auto;
  background-color: white;
  border: 0.15vw solid #dcdcdc;
  padding: 4%;
`;

const OperatingEnvironmentTable = styled.table`
  width: 70%;
  border-collapse: collapse;
  margin-left: 2vw;
  text-align: left;
  th,
  td {
    border: 1px solid #dcdcdc;
    font-size: 3vw;
  }
`;

const OperatingEnvironmentTd = styled.td`
  background-color: ${(props) => props.backgroundcolor || "white"};
  padding: 2vw;
  white-space: nowrap;
  text-align: ${(props) => props.center || "left"};
  font-size: clamp(3px, 2vw, 16px);

  @media (max-width: 360px) {
    white-space: normal;
  }
`;

const OperatingEnvironment = () => {
  return (
    <>
      <OperatingEnvironmentContainer>
        <OperatingEnvironmentText fontcolor="black" textweight="bold">
          電子版株主サービス券スマートフォン推奨ブラウザ環境
        </OperatingEnvironmentText>
        <Common.Blamk margin={"3%"} />
        <OperatingEnvironmentTable>
          <tbody>
            <tr>
              <OperatingEnvironmentTd
                center={"center"}
                backgroundcolor={colors.whitesmoke}
              >
                Android
              </OperatingEnvironmentTd>
              <OperatingEnvironmentTd>
                Google Chrome（最新版）
              </OperatingEnvironmentTd>
            </tr>
            <tr>
              <OperatingEnvironmentTd
                center={"center"}
                backgroundcolor={colors.whitesmoke}
              >
                iPhone
              </OperatingEnvironmentTd>
              <OperatingEnvironmentTd>Safari（最新版）</OperatingEnvironmentTd>
            </tr>
          </tbody>
        </OperatingEnvironmentTable>
        <Common.Blamk margin="3%" />
        <OperatingEnvironmentText>
          ※推奨ブラウザ環境以外でブラウザを開いた際に、カメラが起動しない場合がございます。
        </OperatingEnvironmentText>
      </OperatingEnvironmentContainer>
    </>
  );
};

export default OperatingEnvironment;
