import React from 'react';
import styled from 'styled-components';

const Description = styled.div`
  width: ${props => props.width || '70%'};
  font-weight: ${props => props.weight || 'normal'};
  max-width: ${props => props.maxwidth || '100%'};
  text-align: ${props => props.align || 'center'};
  font-size: clamp(3px, 3vw, ${props => props.textsize || '12px'});
  color: ${props => props.fontcolor || 'black'};
  margin: 0px auto;
  padding: ${props => props.padding || '0px'};
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionText = ({ width, maxwidth, children, fontSize, fontcolor, textalign, padding, fontweight }) => {
  return (
    <Description width={width} maxwidth={maxwidth} textsize={fontSize} fontcolor={fontcolor} align={textalign} padding={padding} weight={fontweight}>
      {children}
    </Description>
  );
};

export default DescriptionText;
