import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import CustomerPage from "./pages/CustomerPage";
import CustomerPage_lock from "./pages/CustomerPage_lock";
import STATION_BOOTHPage from "./pages/STATION_BOOTHPage";
import JRE_MALLPage from "./pages/JRE_MALLPage";
import BatchProcessingScreen from "./pages/BatchProcessingScreen";
import AWS from "aws-sdk";
import s3setstatus from "./lib/AwsSetStatus";
import "./App.css";
import styled from "styled-components";
import { IPAddressCheck } from "./lib/Api";
import { RegularExpression } from "./lib/RegularExpression";
import SignIn from "./pages/SignIn";
import PrivateRoute from "./provider/PrivateRoute";
import LoginRoute from "./provider/LoginRoute";
import { Page404 } from "./pages/page404";

AWS.config.update({
  region: s3setstatus.region,
  credentials: new AWS.Credentials(
    s3setstatus.accessKeyId,
    s3setstatus.secretAccessKey
  ),
});

const AppContainer = styled.div`
  // overflow: auto;
`;

function App() {
  const [ret, setRet] = useState(true);
  const [ipcheck, setipcheck] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const cloudwatch = new AWS.CloudWatch();
      const params = {
        StartTime: new Date(Date.now() - 60000000),
        EndTime: new Date(),
        MetricDataQueries: [
          {
            Id: "m1",
            MetricStat: {
              Metric: {
                Dimensions: [
                  {
                    Name: "Domain",
                    // Value: 'localhost:3000',
                    Value: "amplifyapp.com",
                  },
                  {
                    Name: "URL",
                    // Value: 'http://localhost:3000/',
                    Value: "https://amplifyapp.com",
                  },
                ],
                MetricName: "CustomAccessMetric",
                Namespace: "CustomMetrics",
              },
              Period: 60,
              Stat: "Sum",
            },
          },
        ],
      };

      cloudwatch.getMetricData(params, function (err, data) {
        if (err) {
          //console.error(err, err.stack);
        } else {
          const sumValue = data.MetricDataResults[0].Values.reduce(
            (acc, val) => acc + val,
            0
          );
          //console.log(sumValue)
          setRet(sumValue < 10000000);
        }
      });
    };

    const fetchipaddress = async () => {
      try {
        // const ipcheck_response = await IPAddressCheck();
        // setipcheck(ipcheck_response);
        setipcheck(true);
      } catch {
        setipcheck(false);
      }
    };

    //fetchData();
    fetchipaddress();

    //const intervalId = setInterval(fetchData, 30000);

    //return () => clearInterval(intervalId);
  }, []);

  const currentURL = window.location.href;
  const match = currentURL.match(RegularExpression.UniqueURL);
  let path = "";
  if (match === null) {
    path = "/";
  } else {
    path = "/" + match[1];
  }

  const allowedRoutes = [
    "/admin",
    "/batchhistory",
    "/batchProcessingScreen",
    "/login",
  ];
  return (
    <AppContainer>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
      <Router>
        <div className="App">
          <Routes>
            {allowedRoutes.includes(path) && (
              <>
                {ipcheck === true && (
                  <>
                    <Route
                      path="/admin"
                      element={
                        <PrivateRoute>
                          <CustomerPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/batchProcessingScreen"
                      element={
                        <PrivateRoute>
                          <BatchProcessingScreen />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <LoginRoute>
                          <SignIn />
                        </LoginRoute>
                      }
                    />
                  </>
                )}
                {ipcheck === false && (
                  <>
                    <Route path="/admin" element={<CustomerPage_lock />} />
                    <Route
                      path="/batchhistory"
                      element={<CustomerPage_lock />}
                    />
                    <Route
                      path="/batchProcessingScreen"
                      element={<CustomerPage_lock />}
                    />
                    <Route path="/login" element={<CustomerPage_lock />} />
                  </>
                )}
              </>
            )}
            {!allowedRoutes.includes(path) && (
              <>
                {ret ? (
                  <>
                    <Route
                      path="/:randomValue/STATION_BOOTH"
                      element={<STATION_BOOTHPage />}
                    />
                    <Route
                      path="/:randomValue/JRE_MALL"
                      element={<JRE_MALLPage />}
                    />
                    {/* <Route path = "/:randomValue/JRE_PASSPORT" element={<JRE_PASSPORTPage/>} /> */}
                    <Route path="/" element={<Page404 />} />
                    <Route path="/:randomValue" element={<HomePage />} />
                  </>
                ) : (
                  <Route path="*" element={<Page404 />} />
                )}
              </>
            )}
          </Routes>
        </div>
      </Router>
    </AppContainer>
  );
}

export default App;
