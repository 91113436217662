import React from 'react';
import styled from 'styled-components';
import * as Common from '../components/index';

const HeaderContainer = styled.div`
  ${props => props.imageheight && `height: ${props.imageheight};`}
`;

const Text = styled.div`
  font-weight: bold;
  width: fit-content;
  max-width: 100%;
  color: white;
  font-size: 4vw;
  background-color: green;
  text-align: ${props => (props.textalign || "top")};
  border-radius: 1.5vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left:5%;
  padding-right:5%;
`;

const PageHeader = ({imagepath,headertitle,maxwidth,width,imageheight, imgh}) => {
  return (
    <HeaderContainer imageheight={imageheight}>
        {imagepath !== undefined && (
            <>
             <Common.SetTitleImage 
              imagepath = {process.env.PUBLIC_URL + imagepath} 
              maxwidth = {maxwidth}
              width = {width}
              imgh = {imgh}
            />
            </>
        )}
        <Common.Blamk margin="1vw"/>
        {headertitle !== undefined && (
            <>
            <Text>{headertitle}</Text>
            </>
        )}
        <Common.Blamk margin="1vw"/>
    </HeaderContainer>
  );
};

export default PageHeader;
