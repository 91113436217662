import React from 'react';
import * as Common from '../components/index';
import { Enum } from '../lib/Enum';

const Branch = ({ children,datasetcheck,status }) => {
  const DescriptionTextprops = {
    width:"100%",
    fontSize:"20px",
    textalign:"top",
    fontweight:"normal",
  }

  return (
    <>
    {datasetcheck === Enum.displaystatus.Success && status === true && (
        <>
        {children}
        </>
    )}
    {datasetcheck === Enum.displaystatus.Success && status === false && (
     <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={DescriptionTextprops.textalign} fontweight={DescriptionTextprops.fontweight}>
      Comming Soon
     </Common.DescriptionText>
    )}
    {datasetcheck === Enum.displaystatus.Failure && (
      <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={DescriptionTextprops.textalign} fontweight={DescriptionTextprops.fontweight}>
      URLに誤りがあります
     </Common.DescriptionText>
    )}
    {datasetcheck === Enum.displaystatus.Stopuse && (
    <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={DescriptionTextprops.textalign} fontweight={DescriptionTextprops.fontweight}>
    ご利用停止中です
   </Common.DescriptionText>
    )}
    {datasetcheck === Enum.displaystatus.Error && (
      <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={DescriptionTextprops.textalign} fontweight={DescriptionTextprops.fontweight}>
      Error occurred
     </Common.DescriptionText>
    )}
    {datasetcheck === Enum.displaystatus.Load && (
    <div>
        <Common.LoadingScreen />
    </div>
    )}
    </>
  );
};

export default Branch;
