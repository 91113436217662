import React from 'react';
import styled from 'styled-components';

const StyledSpaceBetween = styled.div`
  margin-top: ${props => props.margin || '0px'};
`;

const Blank = ({ margin }) => {
  return (
    <StyledSpaceBetween margin={margin} />
  );
};

export default Blank;