import React, { useState, useEffect } from "react";
import * as Common from "../components/index";
import services_status from "../lib/ServiceStatus";
import { RegularExpression } from "../lib/RegularExpression";
import { Enum } from "../lib/Enum";
import { GetShareholder, GetCount } from "../lib/Api";
import generateRandomString from "../lib/util";
import { RequestBody } from "../lib/RequestBody";

const HomePage = () => {
  const [services_used, setservices_used] = useState([]);
  const [service_url, setservice_url] = useState([]);
  const [getServicecount, setgetServicecount] = useState(0);
  const [getServicecount_before, setgetServicecount_before] = useState(0);
  const [datasetcheck, setdatasetcheck] = useState(Enum.displaystatus.Load);
  const title = "サービス券選択";
  const [loadingTooLong, setLoadingTooLong] = useState(false);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoadingTooLong(true);
    }, 3000);

    const fetchShareholderData = async () => {
      try {
        const currentURL = window.location.href;
        const pattern = RegularExpression.UniqueURL;
        const match = currentURL.match(pattern);
        const response = await GetShareholder(match[1]);
        return response;
      } catch (error) {
        return 0;
      }
    };

    const fetchData = async (card_number, pin) => {
      try {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedTime = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}${currentDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;

        const key = "b107612bd8f43b2d86b8fcee57ba9b48";
        const cc1 = "2d4d3f507f4ebe74";
        const cn = card_number;
        const p = pin;
        const sc = generateRandomString();
        const { oc, up, kc, tc, lc, cf, rc, osc, ar, crf } =
          RequestBody.countget_2;

        const Body = `key=${key}&cc1=${cc1}&cn=${cn}&p=${p}&oc=${oc}&sc=${sc}&up=${up}&kc=${kc}&tc=${tc}&lc=${lc}&cf=${cf}&rc=${rc}&osc=${osc}&od=${formattedDate}&ot=${formattedTime}&ar=${ar}&crf=${crf}`;

        const response = await GetCount(Body);
        const response_match = response.data
          .match(RegularExpression.responsePattern)[1]
          .match(RegularExpression.dataPattern);
        return response_match;
      } catch (error) {
        // エラーハンドリング
        console.error("Error fetching data:", error);
        throw error; // エラーを呼び出し元に投げる
      }
    };

    const fetchDataAndProcess = async () => {
      const services_used_add = [];
      const service_url_add = [];

      try {
        const Shareholder = await fetchShareholderData();
        const Shareholder_Array = JSON.parse(Shareholder.data.body);
        //console.log(Shareholder_Array);
        if (Shareholder_Array[0] === undefined) {
          setdatasetcheck(Enum.displaystatus.Failure);
          return;
        }
        const isStop = await fetchData(
          Shareholder_Array[0].contact_number,
          Shareholder_Array[0].pin
        );
        //console.log(isStop);

        if (isStop[1] === "G61") {
          setdatasetcheck(Enum.displaystatus.Stopuse);
          return;
        }
        /*
        if (Shareholder_Array[0].status === false) {
          setdatasetcheck(Enum.displaystatus.Stopuse);
          return;
        }
        */

        for (let index = 0; index < services_status.length; index++) {
          services_used_add.push(services_status[index]);

          if (services_status[index].jre === false) {
            service_url_add.push(
              services_status[index].services_url +
                Shareholder_Array[0].services_used[index].url_param
            );
          } else {
            if (index === 0) {
              service_url_add.push("https://shareholder.favy.jp");
            } else {
              const currentURL = window.location.href;
              const pattern = RegularExpression.UniqueURL;
              const match = currentURL.match(pattern);
              service_url_add.push(
                match[0] + "/" + services_status[index].services_url
              );
            }
          }

          if (getServicecount_before <= getServicecount) {
            setgetServicecount(index + 1);
            setgetServicecount_before(index);
          }
        }

        setservice_url(service_url_add);
        setservices_used(services_used_add);
        setdatasetcheck(Enum.displaystatus.Success);
      } catch (error) {
        setdatasetcheck(Enum.displaystatus.Error);
        return 0;
      }
    };

    fetchDataAndProcess();
    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <Common.BasePage
      title={title}
      header={
        <Common.SetTitleImage
          imagepath={process.env.PUBLIC_URL + "/images/HomePageTitle_logo.png"}
          predefinedMargin="0"
          display="inline-block"
          width={"92%"}
        />
      }
    >
      <Common.Blamk margin={"1%"} />
      <Common.DescriptionText
        width="70%"
        maxwidth="700px"
        fontSize="30px"
        fontcolor="black"
        textalign="left"
        padding="0px"
      >
        サービス券の種類によって利用方法が異なります。それぞれのページに記載のご利用方法をご確認ください。
      </Common.DescriptionText>
      <Common.Blamk margin={"1%"} />
      {datasetcheck === Enum.displaystatus.Success && (
        <>
          {services_used.map((service, index) => (
            <Common.LinkBox
              key={index}
              url={service_url[index]}
              value={service.services_name}
              status={service.status}
              description={service.description}
              image_name={service.image_name}
              image_height={service.image_height}
              verpad={service.verpad}
              horpad={service.horpad}
            />
          ))}
        </>
      )}

      {datasetcheck === Enum.displaystatus.Failure && (
        <div>
          <h3>読み込み失敗</h3>
          <p>発行されていないURLです</p>
        </div>
      )}
      {datasetcheck === Enum.displaystatus.Stopuse && (
        <div>
          <h3>サービス停止中</h3>
          <p>ご利用停止中のコードです</p>
        </div>
      )}
      {datasetcheck === Enum.displaystatus.Error && (
        <div>
          <h3>読み込み失敗</h3>
          <p>エラーが発生しました</p>
        </div>
      )}

      {datasetcheck === Enum.displaystatus.Load && (
        <div>
          <Common.LoadingScreen
            text="読み込み中"
            showLongLoadingMessage={loadingTooLong}
          />
        </div>
      )}
      <Common.Blamk margin="5vw" />
    </Common.BasePage>
  );
};

export default HomePage;
