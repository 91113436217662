import React from 'react';
import styled from 'styled-components';
import * as Common from '../components/index';
import { colors } from '../lib/Colors'; 
import { RegularExpression } from '../lib/RegularExpression';

const StyledLink = styled.a`
  display: flex;
  width: 80%;
  position: relative;
  text-decoration: none;
  color: inherit;
  background-color: white;
  margin: 4% auto;
  box-shadow: 4px 4px 5px #888888;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  align-items: center;
  padding-top: 3%;
  padding-bottom: 3%;
  min-height: 40vw;
  aspect-ratio:3/1;
`;

const LinkboxContainer = styled.div`
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width:5%;
`;

const ImageContainer = styled.div`
  box-sizing: border-box;
  width: 75%;
  max-width: 100%;
  height:55%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  margin-bottom: 2%;
  padding: ${props => (props.$verpad || "0")} ${props => (props.$horpad || "0")};
`;

const Image = styled.img`
  width: auto;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Linkbox_hand = styled.div`
  width: 100%;
  background-color: ${colors.bandgreen};
  height: 100%;
  z-index: 2;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-left: 5%;
`;

const Text = styled.div`
  height: 15%;
  font-weight: bold;
  width: fit-content;
  max-width: 100%;
  color: white;
  font-size: 4vw;
  background-color: green;
  text-align: center;
  border-radius: 1.5vw;
  padding-left:9%;
  padding-right: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
`;

const DescriptionContainer = styled.div`
  width:95%;
  flex:1;
`;

const LinkBox = ({ url, value, status, description, image_name, image_height ,verpad, horpad}) => {
  let button_status = true;
  if (status === false) {
    button_status = false;
  }

  const descriptionWithLineBreaks = description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
  const paths = typeof image_name === 'string' ? [image_name] : image_name;
  const image_height_list = typeof image_height === 'string' ? [image_height] : image_height;
  const matchResult = image_height_list[0].match(RegularExpression.NumberFromVM);
  const image_blamk = (9 - matchResult[0]) * 0.5
  const DescriptionTextprops = {
    width:"84%",
    fontSize:"72px",
    textalign:"left",
    fontweight:"bold",
  }
  return (
    <>
      <StyledLink href={url} target="_self" rel="noopener noreferrer">
        <LinkboxContainer>
          <Linkbox_hand />
        </LinkboxContainer>
        <RightContainer>
          <ImageContainer $verpad={verpad} $horpad={horpad}>
            <Image src={process.env.PUBLIC_URL + "/images/" + paths[0]} height={matchResult[0]} margin = {image_blamk}/>
          </ImageContainer>
          <Text>{value}</Text>
          {button_status && (
            <DescriptionContainer>
              <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={DescriptionTextprops.textalign} fontweight={DescriptionTextprops.fontweight}>
                {descriptionWithLineBreaks}
              </Common.DescriptionText>
              </DescriptionContainer>
          )}
          {!button_status && (
            <DescriptionContainer>
              <Common.DescriptionText width={DescriptionTextprops.width} fontSize={DescriptionTextprops.fontSize} textalign={"center"} fontweight={DescriptionTextprops.fontweight}>
                Comming Soon
                </Common.DescriptionText>
            </DescriptionContainer>
          )}
        </RightContainer>
      </StyledLink>
    </>
  );
}

export default LinkBox;
