import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../provider/AuthContext";

const PrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }
  }, []);

  return children;
};

export default PrivateRoute;
