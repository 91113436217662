import axios from "axios";
import * as xml2js from "xml2js";

export async function GetShareholder(url) {
  const apiUrl =
    "https://0ot39h3fe9.execute-api.ap-northeast-1.amazonaws.com/prod/GetShareholder";
  const headers = {
    "x-api-key": "SspJGLvj1894uW8amzWM943gBDHndSDd9bhQtU21",
  };

  const requestBody = {
    url: "https://jre-shareholder-coupon.jp/" + url,
  };

  return await axios.post(apiUrl, JSON.stringify(requestBody), { headers });
}

export async function GetCount(body) {
  const apiUrl =
    "https://m7ukiowfw9.execute-api.ap-northeast-1.amazonaws.com/prod/GetCount";
  const headers = {
    "x-api-key": "rXFQYSdRv78Cj28x31oOa4i5rZO0wUsd5FKvzOgH",
  };

  return await axios.post(apiUrl, body, { headers });
}

export async function GetInquiry(body) {
  const apiUrl =
    "https://0ot39h3fe9.execute-api.ap-northeast-1.amazonaws.com/prod/GetInquiry";
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": "SspJGLvj1894uW8amzWM943gBDHndSDd9bhQtU21",
  };

  const response = await axios.post(apiUrl, JSON.stringify(body), { headers });
  return response;
}

export async function StopUse(body) {
  const apiUrl =
    "https://0ot39h3fe9.execute-api.ap-northeast-1.amazonaws.com/prod/stopcard";
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": "SspJGLvj1894uW8amzWM943gBDHndSDd9bhQtU21",
  };

  const response = await axios.post(apiUrl, JSON.stringify(body), { headers });
  return response;
}

export async function IPAddressCheck() {
  const ip_white_list = [
    "157.72.48.0",
    "157.72.48.1",
    "157.72.48.2",
    "157.72.48.3",
    "157.72.48.4",
    "157.72.48.5",
    "157.72.48.6",
    "157.72.48.7",
    "157.72.48.8",
    "157.72.48.9",
    "157.72.48.10",
    "157.72.48.11",
    "157.72.48.12",
    "157.72.48.13",
    "157.72.48.14",
    "157.72.48.15",
    "157.72.48.16",
    "157.72.48.17",
    "157.72.48.18",
    "157.72.48.19",
    "157.72.48.20",
    "157.72.48.21",
    "157.72.48.22",
    "157.72.48.23",
    "157.72.48.24",
    "157.72.48.25",
    "157.72.48.26",
    "157.72.48.27",
    "157.72.48.28",
    "157.72.48.29",
    "157.72.48.30",
    "157.72.48.31",
    "157.72.48.32",
    "157.72.48.33",
    "157.72.48.34",
    "157.72.48.35",
    "157.72.48.36",
    "157.72.48.37",
    "157.72.48.38",
    "157.72.48.39",
    "157.72.48.40",
    "157.72.48.41",
    "157.72.48.42",
    "157.72.48.43",
    "157.72.48.44",
    "157.72.48.45",
    "157.72.48.46",
    "157.72.48.47",
    "157.72.48.48",
    "157.72.48.49",
    "157.72.48.50",
    "157.72.48.51",
    "157.72.48.52",
    "157.72.48.53",
    "157.72.48.54",
    "157.72.48.55",
    "157.72.48.56",
    "157.72.48.57",
    "157.72.48.58",
    "157.72.48.59",
    "157.72.48.60",
    "157.72.48.61",
    "157.72.48.62",
    "157.72.48.63",
    "118.151.184.136",
    "118.151.184.137",
    "118.151.184.138",
    "118.151.184.139",
    "118.151.184.140",
    "118.151.184.141",
    "203.180.184.64",
    "203.180.184.65",
    "203.180.184.66",
    "203.180.184.67",
    "203.180.184.68",
    "203.180.184.69",
    "203.180.184.70",
    "203.180.184.71",
    "153.124.181.215",
    "211.133.152.123",
  ];
  const response_ipv4 = await axios.get("https://api.ipify.org?format=json");
  const ipv4_data = response_ipv4.data.ip;
  return ip_white_list.includes(ipv4_data);
}

export async function stopUse(body) {
  try {
    const response = await axios.post(
      "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/stopServices",
      body,
      { headers: { "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN" } }
    );
    const data = await xml2js.parseStringPromise(response.data);
    return data.responseData.responseInfo[0].errorCd[0];
  } catch (error) {
    console.error("Error in stopUse:", error);
    throw error;
  }
}
