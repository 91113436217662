import React from 'react';
import styled from 'styled-components';
import * as Common from './index';
import { colors } from '../lib/Colors';
import BackButton from '../components/BackButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TopHalf = styled.div`
  background-color: ${colors.lightgreen};
  border-top-left-radius: 2600px 720px;
  border-top-right-radius: 2600px 720px;
  overflow: hidden;
  margin: 0 -5%;
  padding-top: 0px;
  padding-bottom:10vw;
`;

const SelectTitle = styled.div`
  width: 30%;
  color: green;
  background-color: white;
  border-radius: 30px;
  font-size: clamp(1px, 3vw, ${props => props.textsize || '24px'});
  margin-top: 6vw;
  margin-bottom: 3vw;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
`;

const BasePage = ({children,title,header, backButton}) => {
  let tabtitle = "";
  if(title !== undefined){
    tabtitle = title;
  }

  return (
    <>
    {tabtitle !== "" && (
      <title>{tabtitle}</title>
    )}
      <Common.Blamk margin={"19vw"}/>
      {header}
      <Common.Blamk margin={"5vw"}/>
      <Container>
       <TopHalf>
         {title !== null && title !== undefined && title !== "" && (
          <SelectTitle textsize = "24px">
            &nbsp;&nbsp;&nbsp;{title}&nbsp;&nbsp;&nbsp;
          </SelectTitle>
         )}
        {children}
       </TopHalf>
      </Container>
      <Common.Blamk margin={"10vw"}/>
      {backButton &&
      <>
        <BackButton />
        <Common.Blamk margin={"10vw"}/>
      </>
      }
      <Common.OperatingEnvironment/>
      <Common.Blamk margin={"30vw"}/>
    </>
  );
}

export default BasePage;
