import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Common from "../components/index";
import services_status from "../lib/ServiceStatus";
import { RegularExpression } from "../lib/RegularExpression";
import { GetShareholder, GetCount } from "../lib/Api";
import { Enum } from "../lib/Enum";
import * as AWS from "aws-sdk";
import s3setstatus from "../lib/AwsSetStatus";
import { RequestBody } from "../lib/RequestBody";
import generateRandomString from "../lib/util";

const config = {
  bucketName: "produniqueqrcode", //qrcodestorage--apne1-az4--x-s3
  region: s3setstatus.region, // replace with your S3 region
  accessKeyId: s3setstatus.accessKeyId,
  secretAccessKey: s3setstatus.secretAccessKey,
};

const QRCodeStyled = styled.div`
  width:50%;
  height: auto;
  padding: 0.5vw;
  background-color: white;
  margin: 0 auto;
  border: none;
  text-align: center;
  aspect-ratio:1/1;
  padding: 5vw;
  img {
    object-fit="contain"
  }
`;

const Remark = styled.div`
  background: white;
  margin: 0 auto;
  border-radius: 10px;
  border: none;
  padding: 5%;
  width: 70%;
  text-align: left;
  font-size: clamp(3px, 3vw, ${(props) => props.textsize || "24px"});
  p,
  ul {
    margin-left: 10px;
    padding-left: 0;
  }
  > p {
    margin-bottom: 1vw;
    margin-left: 0;
  }
  > ul {
    list-style-type: disc;
    padding-left: 15px;
    margin-top: 0;
  }
  ul ul {
    list-style-type: none;
    padding-left: 10px;
  }
  ul ul li {
    position: relative;
  }
  ul ul li:before {
    content: "- ";
    position: absolute;
    left: -3vw;
  }
`;

const Text = styled.p`
  color: green;
  font-weight: bold;
  text-align: center;
  font-size: 4.5vw;
  margin-top: 1vw;
`;

const STATION_BOOTHPage = () => {
  const Service_data = services_status[5];
  const [datasetcheck, setdatasetcheck] = useState(Enum.displaystatus.Load);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getqrcode = async (image_name) => {
      try {
        AWS.config.update({
          accessKeyId: s3setstatus.accessKeyId,
          secretAccessKey: s3setstatus.secretAccessKey,
          region: s3setstatus.region,
        });

        const s3 = new AWS.S3();

        // 署名付きURLの生成
        const params = {
          Bucket: "produniqueqrcode", // バケット名
          Key: image_name, // オブジェクトのキー（ファイルパス）
          Expires: 300, // 有効期限（秒）
        };

        s3.getSignedUrl("getObject", params, (err, signedUrl) => {
          if (err) {
            console.error("Error generating signed URL:", err);
          } else {
            setImageURL(signedUrl);
            // 署名付きURLをここで利用するか、stateにセットするなどの処理を追加
          }
        });
      } catch (error) {
        if (error.code === "SomeSpecificErrorCode") {
          // 特定のエラーコードに基づく処理
        } else {
          console.error(
            "画像URLの取得エラー:",
            error.message,
            error.code,
            error.stack
          );
        }
      }
    };
    const fetchData = async () => {
      try {
        const currentURL = window.location.href;
        const pattern = RegularExpression.UniqueURL;
        const match = currentURL.match(pattern);
        const resultString = match[1].replace("/STATION_BOOTH", "");
        const response = await GetShareholder(resultString);
        return response;
      } catch (error) {
        console.error("Error fetching data:", error);
        setdatasetcheck("Error");
        return 0;
      }
    };

    const checkIsStop = async (card_number, pin) => {
      try {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedTime = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}${currentDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;

        const key = "b107612bd8f43b2d86b8fcee57ba9b48";
        const cc1 = "2d4d3f507f4ebe74";
        const cn = card_number;
        const p = pin;
        const sc = generateRandomString();
        const { oc, up, kc, tc, lc, cf, rc, osc, ar, crf } =
          RequestBody.countget_2;

        const Body = `key=${key}&cc1=${cc1}&cn=${cn}&p=${p}&oc=${oc}&sc=${sc}&up=${up}&kc=${kc}&tc=${tc}&lc=${lc}&cf=${cf}&rc=${rc}&osc=${osc}&od=${formattedDate}&ot=${formattedTime}&ar=${ar}&crf=${crf}`;

        const response = await GetCount(Body);
        const response_match = response.data
          .match(RegularExpression.responsePattern)[1]
          .match(RegularExpression.dataPattern);
        return response_match;
      } catch (error) {
        // エラーハンドリング
        console.error("Error fetching data:", error);
        throw error; // エラーを呼び出し元に投げる
      }
    };

    const fetchDataAndProcess = async () => {
      const Shareholder = await fetchData();
      const Shareholder_Array = JSON.parse(Shareholder.data.body);
      if (Shareholder_Array[0] === undefined) {
        setdatasetcheck(Enum.displaystatus.Failure);
        return;
      }
      const isStop = await checkIsStop(
        Shareholder_Array[0].contact_number,
        Shareholder_Array[0].pin
      );
      if (isStop[1] === "G61") {
        setdatasetcheck(Enum.displaystatus.Stopuse);
        return;
      }
      getqrcode(Shareholder_Array[0].qrcodepath);
      setdatasetcheck(Enum.displaystatus.Success);
    };

    fetchDataAndProcess();
  }, []);

  return (
    <>
      <Common.BasePage
        header={
          <Common.PageHeader
            imagepath={"/images/ServiceLogo/06_StaionBooth_Logo.png"}
            headertitle={"STATION BOOTH"}
            maxwidth={"300px"}
            width={"33vw"}
            imageheight={"30vw"}
          />
        }
        backButton={true}
      >
        <title>STATION BOOTH</title>
        {datasetcheck === Enum.displaystatus.Success && (
          <>
            <div>
              <Common.Blamk margin="15%" />
              <Text>１時間無料コード</Text>
              <Common.Blamk margin="2vw" />
              <QRCodeStyled id="qrcode-container">
                <img src={imageURL} alt="S3 Image" width="100%" />
              </QRCodeStyled>
            </div>
            <Common.Blamk margin="15%" />
            <Remark>
              <p style={{ fontWeight: "bold" }}>【ご利用方法】</p>
              <ul>
                <li>
                  事前のご予約はできません。ブースの上部にある緑色の「空」ランプが点灯しているブースにてご利用いただけます。
                </li>
                <li>
                  ご利用の際はブースの扉脇のタッチパネルディスプレイより、「会員」→「QRコード」を選択し、白色のQRリーダーに株主優待券のQRコードをかざしてください。その後ご利用時間を選択されると扉の鍵が解錠されますので、扉を開けてご利用ください。詳しいご利用方法は
                  <a href="https://www.stationwork.jp/help/usage">こちら</a>
                </li>
              </ul>
              <br />
              <p style={{ fontWeight: "bold" }}>【注意事項】</p>
              <ul>
                <li>
                  割引券は1枚につき、お一人様一回の割引に利用することができます。最大1時間までご利用可能です。
                </li>
                <li>
                  利用券はほかの優待、割引制度との重複適用はいたしません。
                </li>
                <li>現金との引換はいたしません。</li>
                <li>
                  以下のサービスは対象“外”となります。（2024年3月時点）
                  <ul>
                    <li>一部のSTATION BOOTH(QRリーダーが無い拠点)</li>
                    <li>STATION BOOTH Light</li>
                    <li>STATION BOOTH Air</li>
                    <li>STATION DESK</li>
                    <li>提携店(ホテルシェアオフィス)</li>
                    <li>提携店(コワーキング等)</li>
                    <li>外部連携先の拠点</li>
                  </ul>
                </li>
              </ul>
            </Remark>
          </>
        )}
        {datasetcheck === Enum.displaystatus.Stopuse && (
          <div>
            <h3>サービス停止中</h3>
            <p>ご利用停止中のコードです</p>
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Load && (
          <div>
            <Common.LoadingScreen text="読み込み中" />
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Failure && (
          <div>
            <h3>読み込み失敗</h3>
            <p>発行されていないURLです</p>
          </div>
        )}
        <Common.Blamk margin="5%" />
      </Common.BasePage>
    </>
  );
};

export default STATION_BOOTHPage;
