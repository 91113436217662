import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../lib/Colors";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.colorless};
  background-size: cover;
  filter: blur(4px);
  z-index: -1;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
`;

const rotate = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1vw solid #f3f3f3; /* 5分の1に縮小 */
  border-top: 1vw solid ${colors.bandgreen}; /* 5分の1に縮小 */
  border-radius: 50%;
  width: 20vw; /* 画面横幅の30%に設定 */
  max-width: 200px; /* 最大値を200pxに制限 */
  height: 20vw; /* 画面横幅の30%に設定 */
  max-height: 200px; /* 最大値を200pxに制限 */
  animation: ${rotate} 2s linear infinite;
`;

const LoadText = styled.div`
  position: absolute;
  top: calc(50% + 12vw); /* ローディングアイコンの中心から少しだけ下に */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(3px, 2vw, 16px);
  color: #333;
  text-align: center;
  pointer-events: none;
`;

const LoadingScreen = ({ text, showLongLoadingMessage = false }) => (
  <>
    <Background />
    <LoaderContainer>
      <Loader />
      {text !== null && text !== undefined && <LoadText>{text}</LoadText>}
      {showLongLoadingMessage && (
        <LoadText style={{ top: "calc(50% + 15vw)" }}>
          アクセスが集中しています。しばらくお待ちください。
        </LoadText>
      )}
    </LoaderContainer>
  </>
);

export default LoadingScreen;
