import React from 'react';
import styled from 'styled-components';

const InputBox = styled.input`
  max-width:${props => (props.maxwidth || "90vw")};
  width: ${props => (props.width || "600px")};
  height: ${props => (props.height || "20px")};
  margin-top: ${props => (props.margintop || "3px")};
  overflow: ${props => (props.overflow || "hidden")};
  text-overflow: ${props => (props.textoverflow || "ellipsis")};
  white-space:${props => (props.whitespace || "nowrap")};
  ${props => props.type === 'number' && `max-length: 4;`}
`;

const InputTextForm = ({ type,placeholder,value,onChange,maxwidth,width,height,margintop,overflow,textoverflow,whitespace }) => {
  return (
    <InputBox 
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    maxwidth = {maxwidth} 
    width = {width} 
    height = {height} 
    margintop = {margintop} 
    overflow = {overflow} 
    textoverflow = {textoverflow} 
    whitespace = {whitespace}/>
  );
};

export default InputTextForm;
