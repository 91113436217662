import React, { useState } from "react";

const CustomerPage_lock = () => {
  return (
    <div>
      <h2>管理画面</h2>
      <title>管理画面</title>
      <h1>IPアドレスが異なるのでアクセスできません</h1>
    </div>
  );
};

export default CustomerPage_lock;
