import React from 'react';
import styled from 'styled-components';

const Imagealign = styled.div`
  width: 90%;
  margin: 0 auto;
  ${props => props.imgh && `height: ${props.imgh};`}
  width: ${props => (props.width || "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Imagelayout = styled.img`
  width: ${props => (props.width || "100%")};
`;

const SetTitleImage = ({maxwidth, width, imagepath, textAlign, imageheight, imgh}) => {

  return (
    <Imagealign textalign={textAlign} imageheight={imageheight} imgh = {imgh} width={width}>
      <Imagelayout maxwidth = {maxwidth} width={width} src={imagepath}/>
    </Imagealign>
  );
};

export default SetTitleImage;
