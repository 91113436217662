import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as Common from "../components/index";
import services_status from "../lib/ServiceStatus";
import { RequestBody } from "../lib/RequestBody";
import { FaExclamationCircle } from "react-icons/fa";
import { GetInquiry, GetCount, StopUse, stopUse } from "../lib/Api";
import { RegularExpression } from "../lib/RegularExpression";
import { Enum } from "../lib/Enum";
import generateRandomString from "../lib/util";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../provider/AuthContext";
import HeaderLayout from "../components/HeaderLayout";

const SContent = styled.div`
  text-align: left;
  padding-left: 100px;
  padding-right: 100px;
`;

const SContainer = styled.div`
  background-color: #f2f2f2;
  display: inline-flex;
  flex-direction: row;
  //justify-content: center;
  padding: 20px;
  width: auto;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const SField = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 30%;
`;

const SLabel = styled.label`
  font-size: 0.7em; // 文字サイズを小さくする
  margin-bottom: 3px; // 下側のマージンを設定
  font-weight: bold; // 文字を太字にする
  color: #2b2929; // 色を#2B2929にする
  margin-left: 2px;
`;

const SInput = styled.input`
  margin-right: 10px;
  border-radius: 4px; // 角を少し丸くする
  border: 0.5px solid #a9a9a9;
  height: 30px;
`;

const SSearchButtonWapper = styled.div`
  display: flex;
  flex-grow: 1;
  //width: 10%;
`;

const SButton = styled.button`
  background-color: ${(props) => (props.primary ? "#008000" : "#ffffff")};
  color: ${(props) => (props.primary ? "#ffffff" : "#008000")};
  font-weight: bold;
  border-radius: 4px;
  border: ${(props) => (props.primary ? "none" : "2px solid #008000")};
  margin-right: 10px;
  //width: 100%;
  margin-top: 18px;
  //flex-basis: 50%;
  cursor: pointer;
  flex: 1;
  height: 32px;
`;

const SResetButton = styled(SButton)`
  border: 0.1px solid #008000;
`;

const SBar = styled.div`
  width: 100%; // 横幅を100%に設定
  height: 2px; // 高さを2pxに設定
  background-color: #dddddd; // 色を#ddddddに設定
`;

const SInfoContainer = styled.div`
  background-color: #f2f2f2;
  display: inline-flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
  width: auto;
  //align-items: center;
  //height: 30px;
  //margin-right: 55%;
  font-size: 0.8em;
`;

const SInfoItem = styled.div`
  background-color: #ffffff;
  border-radius: 4px; // 角を丸くする
  padding: 0 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: auto;
  display: flex;
`;

const ServiceTable = styled.table`
  width: 70%;
  border-collapse: collapse;
  text-align: center;
  //background-color: #f2f2f2; // テーブルの背景色を#f2f2f2に設定
  margin-bottom: 30px;

  th,
  td {
    font-size: 18px;
    padding: 8px;
    border-bottom: 1px solid #ddd; // 各行の下に灰色の横棒
    border-left: none; // 縦棒を削除
    border-right: none; // 縦棒を削除
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    border-bottom: 2px solid #008000; // ヘッダの下に緑の横棒
    color: #008000; // テーブルヘッダの文字色を緑に設定
  }

  tbody {
    background-color: #f7f7f7; // テーブルbodyの背景色を#f2f2f2に設定
  }
`;
const ButtonContainer = styled.button`
  width: 30vw;
  min-width: fit-content;
  margin-top: 10px;
  margin-bottom: 50px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 7px 10px;
  background-color: #008000;
  border: none;
  cursor: pointer;
`;

const ButtonContainer_deactive = styled.button`
  width: 30vw;
  min-width: fit-content;
  margin: 0px auto;
  margin-top: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 7px 10px;
  background-color: gray;
  border: none;
  pointer-events: none;
  margin-bottom: 50px;
`;

const CountContainer = styled.div`
  display: flex;
`;

const CountTextContainer = styled.div`
  flex: 2;
  color: ${(props) => props.color || "black"};
  text-align: center;
`;

const StyledIcon = styled(FaExclamationCircle)`
  flex: 1;
  color: ${(props) => props.color || "red"};
  //font-size: 16px;
  //margin-top: 3px;
  //vertical-align: middle;
  //height: 100%;
  align-self: center;
`;

const CustomerPage = () => {
  const [url, seturl] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [pin, setPin] = useState("");
  const [servicesUsed, setServicesUsed] = useState([]);
  const [countlist, setcountlist] = useState([]);
  const [services_count_api, setservices_count_api] = useState([]);
  const [searchTerm_contactNumber, setSearchTerm_contactNumber] = useState("");
  const [searchTerm_pin, setsearchTerm_pin] = useState("");
  const [searchTerm_contactNumber_input, setSearchTerm_contactNumber_input] =
    useState("");
  const [searchTerm_pin_input, setsearchTerm_pin_input] = useState("");
  const [datasetcheck, setdatasetcheck] = useState([]);
  const [listingError, setListingError] = useState(false);
  const [errorCodeArray, setErrorCodeArray] = useState([]);
  const [stoppedCard, setStoppedCard] = useState(false);
  const [canRequest, setCanRequest] = useState(true);
  const [stopRequestDone, setStopRequestDone] = useState(false);
  const [autoSearch, setAutoSearch] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);

  /*バッチ履歴のエラー詳細から遷移する場合クリックハンドラにより処理が自動で発火するようにする*/
  useEffect(() => {
    const dataFromDetail = location.state?.data;
    if (dataFromDetail) {
      setSearchTerm_contactNumber(dataFromDetail.contactNumber);
      setsearchTerm_pin(dataFromDetail.pin);
      setAutoSearch(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (autoSearch && searchTerm_contactNumber && searchTerm_pin) {
      handleSearch();
      setAutoSearch(false);
    }
  }, [searchTerm_contactNumber, searchTerm_pin]);

  useEffect(() => {
    if (reset) {
      navigate(location.pathname, { replace: true, state: {} });
      seturl("");
      setContactNumber("");
      setPin("");
      setServicesUsed([]);
      setcountlist([]);
      setservices_count_api([]);
      setSearchTerm_contactNumber("");
      setsearchTerm_pin("");
      setSearchTerm_contactNumber_input("");
      setsearchTerm_pin_input("");
      setdatasetcheck([]);
      setListingError(false);
      setErrorCodeArray([]);
      setStoppedCard(false);
      setCanRequest(true);
      setStopRequestDone(false);
      setReset(false);
    }
  }, [reset]);

  const resetAndRedirect = () => {
    if (window.confirm("検索情報をリセットしますか？")) {
      setReset(true);
    }
  };

  const fetchShareholderData = async () => {
    try {
      const requestBody = {
        contact_number: searchTerm_contactNumber,
        pin: searchTerm_pin,
      };
      const response = await GetInquiry(requestBody);
      return response;
    } catch (error) {
      throw error;
      //console.error('Error fetching data:', error);
      //return 0;
    }
  };

  const handleSearch = async () => {
    setStopRequestDone(false);
    if (searchTerm_contactNumber === "" || searchTerm_pin === "") {
      window.alert("お問い合わせ番号またはPINが入力されていません");
      return;
    }

    setdatasetcheck(Enum.displaystatus.Load);

    try {
      const Shareholder = await fetchShareholderData();
      if (Shareholder.data === 0) {
        setdatasetcheck(Enum.displaystatus.Failure);
        return;
      }
      //console.log(Shareholder.data);
      const isStop = await fetchData(
        Shareholder.data.contact_number,
        Shareholder.data.pin
      );
      //console.log(isStop);
      //console.log(isStop[1] === "G61");
      if (isStop[1] === "G61") {
        setdatasetcheck(Enum.displaystatus.Stopuse);
        return;

        /*
      if (Shareholder.data.status === false) {
        setdatasetcheck(Enum.displaystatus.Stopuse);
        return;
      }
    */
      } else {
        const response_json = Shareholder.data;
        seturl(response_json.url || "");
        setContactNumber(response_json.contact_number || "");
        setPin(response_json.pin || "");
        setServicesUsed(response_json.services_used || []);

        const countlist_add = [];
        const api_count_add = [];
        const errorCodeArrayForAdd = [];
        const errorResArray = [];
        for (
          let index = 0;
          index < response_json.services_used.length;
          index++
        ) {
          if (services_status[index].jre === false) {
            const res = await fetchData(
              response_json.services_used[index].card_number,
              response_json.services_used[index].pin
            );
            const errorCode = res[1];
            if (errorCode !== "***" && errorCode !== "G61") {
              const errorInfo = { index: index, errorCode: errorCode };
              errorResArray.push(errorInfo);
            }
            const response_int = parseInt(res[9], 10);
            if (response_int === services_status[index].count) {
              api_count_add.push(true);
            } else {
              api_count_add.push(false);
            }
            countlist_add.push(response_int);
            errorCodeArrayForAdd.push(errorCode);
          } else {
            api_count_add.push(true);
            countlist_add.push(0);
            errorCodeArrayForAdd.push("***");
          }
        }
        setcountlist(countlist_add);
        setservices_count_api(api_count_add);
        setdatasetcheck(Enum.displaystatus.Success);
        setErrorCodeArray(errorCodeArrayForAdd);
        if (errorResArray.length === 0) {
          setListingError(false);
        } else {
          setListingError(errorResArray);
        }
        for (let i = 0; i < errorCodeArrayForAdd.length; i++) {
          if (errorCodeArrayForAdd[i] === "G61") {
            setStoppedCard(true);
            break;
          }
          if (i === errorCodeArrayForAdd.length - 1) {
            setStoppedCard(false);
          }
        }
        for (let i = 0; i < api_count_add.length; i++) {
          if (!api_count_add[i]) {
            setCanRequest(false);
            break;
          }
          if (i === api_count_add.length - 1) {
            setCanRequest(true);
          }
        }
      }
      setSearchTerm_contactNumber_input(searchTerm_contactNumber);
      setsearchTerm_pin_input(searchTerm_pin);
    } catch (error) {
      setdatasetcheck(Enum.displaystatus.Error);
      return;
    }
  };

  const fetchData = async (card_number, pin) => {
    try {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDate.getDate().toString().padStart(2, "0")}`;
      const formattedTime = `${currentDate
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      const key = "b107612bd8f43b2d86b8fcee57ba9b48";
      const cc1 = "2d4d3f507f4ebe74";
      const cn = card_number;
      const p = pin;
      const sc = generateRandomString();
      const { oc, up, kc, tc, lc, cf, rc, osc, ar, crf } =
        RequestBody.countget_2;

      const Body = `key=${key}&cc1=${cc1}&cn=${cn}&p=${p}&oc=${oc}&sc=${sc}&up=${up}&kc=${kc}&tc=${tc}&lc=${lc}&cf=${cf}&rc=${rc}&osc=${osc}&od=${formattedDate}&ot=${formattedTime}&ar=${ar}&crf=${crf}`;

      const response = await GetCount(Body);
      const response_match = response.data
        .match(RegularExpression.responsePattern)[1]
        .match(RegularExpression.dataPattern);
      return response_match;
    } catch (error) {
      // エラーハンドリング
      console.error("Error fetching data:", error);
      throw error; // エラーを呼び出し元に投げる
    }
  };

  //DBのステータス変更、停止リクエスト送信
  /**
   * 最初のハンドラで株主のステータスチェックしてるからここでその状態に基づいた分岐はいらないはず
   * このハンドラは株主のステータスがtrueのときしか発火できない
   * ギフトカードに紐づいていないサービス（MALLとか）についてもループ対象になってるから修正 --> 修正済み
   * 株主ステータスをfalseにする必要あり --> 修正済み
   */
  const stop_use = async () => {
    if (
      window.confirm(
        "サービス利用停止リクエストを送信しますがよろしいでしょうか？"
      ) === true
    ) {
      try {
        setdatasetcheck("DeleteLoad");
        const allSuccess = true;
        for (let index = 0; index < 9; index++) {
          if (
            services_status[index].jre === false &&
            errorCodeArray[index] !== "G61"
          ) {
            const errorCode = await stop_use_api(
              servicesUsed[index].card_number,
              servicesUsed[index].pin
            );
            if (errorCode !== "0") {
              allSuccess = false;
            }
          }
        }
        setdatasetcheck(Enum.displaystatus.Success);
        if (allSuccess) {
          try {
            const res_stop_contact_number = await stop_use_api(
              searchTerm_contactNumber,
              searchTerm_pin
            );
            const res = CardStatusSet();
            setStopRequestDone("success");
          } catch (e) {
            console.log(e);
            setdatasetcheck(Enum.displaystatus.Error);
            return;
          }
        } else {
          setStopRequestDone("failed");
        }
      } catch (error) {
        console.log(error);
        setdatasetcheck(Enum.displaystatus.Error);
        return;
        //window.alert(error);
      }
    }
  };

  /**
   * 利用停止APIを実際に叩く処理
   *
   */
  const stop_use_api = async (card_number, pin) => {
    try {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDate.getDate().toString().padStart(2, "0")}`;
      const formattedTime = `${currentDate
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      const cn = card_number;
      const p = pin;
      const od = formattedDate;
      const ot = formattedTime;
      const sc = generateRandomString();
      const { key, cc1, oc, rs, lc, ar, rm } = RequestBody.stopcard;
      const body = `key=${key}&cc1=${cc1}&cn1=${cn}&p1=${p}&oc=${oc}&rs=${rs}&sc=${sc}&lc=${lc}&od=${od}&ot=${ot}&ar=${ar}&rm=${rm}`;
      const response = stopUse(body);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const CardStatusSet = async () => {
    const requestBody = {
      url: url,
      contact_number: contactNumber,
    };
    try {
      const response = StopUse(requestBody);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("CardStatusCheckでの例外");
    }
  };

  const ButtonComponent = services_count_api.some(
    (value) => value === false
  ) ? (
    <ButtonContainer_deactive>利用停止リクエスト不可</ButtonContainer_deactive>
  ) : (
    <ButtonContainer onClick={stop_use}>利用停止リクエスト送信</ButtonContainer>
  );

  return (
    <div>
      <HeaderLayout />
      <title>管理画面</title>
      <SContent>
        <span style={{ textAlign: "left" }}>
          お問い合わせ番号とPINから検索、利用停止リクエストの送信が可能です
        </span>
        <br></br>
        <SContainer>
          <SField>
            <SLabel>お問い合わせ番号</SLabel>
            <SInput
              placeholder="お問い合わせ番号"
              value={searchTerm_contactNumber}
              onChange={(e) => setSearchTerm_contactNumber(e.target.value)}
            />
          </SField>
          <SField>
            <SLabel>PIN</SLabel>
            <SInput
              placeholder="PIN"
              value={searchTerm_pin}
              onChange={(e) => setsearchTerm_pin(e.target.value)}
            />
          </SField>
          <SSearchButtonWapper>
            <SButton primary onClick={handleSearch}>
              検索
            </SButton>
            <SResetButton onClick={resetAndRedirect}>リセット</SResetButton>
          </SSearchButtonWapper>
        </SContainer>
        <SBar />
        {/* 利用停止が存在しない場合。初期知と全部一致してる場合と一致してない場合の二通りがこれでok*/}
        {((datasetcheck === Enum.displaystatus.Success &&
          !listingError &&
          !stoppedCard &&
          !stopRequestDone) ||
          (datasetcheck === "DeleteLoad" &&
            !listingError &&
            !stoppedCard &&
            !stopRequestDone)) && (
          <div>
            {services_count_api.some((value) => value === false) ? (
              <h4 style={{ color: "red" }}>
                既にご利用済みのサービスがあります
              </h4>
            ) : (
              <h4>読み取り成功</h4>
            )}
            <SInfoContainer>
              <span
                style={{
                  color: "gray",
                  marginRight: "10px",
                  verticalAlign: "bottom",
                }}
              >
                検索内容
              </span>
              <SInfoItem>
                <div style={{ color: "gray", marginRight: "10px" }}>
                  お問い合わせ番号
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {searchTerm_contactNumber_input}
                </div>
              </SInfoItem>
              <SInfoItem>
                <div style={{ color: "gray", marginRight: "10px" }}>PIN</div>
                <div style={{ fontWeight: "bold" }}>{searchTerm_pin_input}</div>
              </SInfoItem>
            </SInfoContainer>
            <ServiceTable>
              <thead>
                <tr>
                  <th>サービス名</th>
                  <th>カード番号</th>
                  <th>残高</th>
                  <th>稼働状況</th>
                </tr>
              </thead>
              <tbody>
                {services_status.map((service, index) => (
                  <React.Fragment key={index}>
                    {service.jre === false && (
                      <tr>
                        <td>{service.services_name}</td>
                        <td>{servicesUsed[index].card_number}</td>
                        <td>
                          {services_count_api[index] === true && (
                            <CountContainer>
                              <CountTextContainer>
                                {countlist[index]}
                              </CountTextContainer>
                              {/*<StyledIcon color="white" />*/}
                            </CountContainer>
                          )}
                          {services_count_api[index] === false && (
                            <CountContainer>
                              <CountTextContainer color="red">
                                {countlist[index]}
                              </CountTextContainer>
                              <StyledIcon />
                            </CountContainer>
                          )}
                        </td>
                        <td>
                          {errorCodeArray[index] === "***" ? "稼働" : "停止"}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </ServiceTable>
            {ButtonComponent}
          </div>
        )}
        {datasetcheck === "DeleteLoad" && (
          <div>
            <h3>停止リクエストの送信中</h3>
            <Common.LoadingScreen text="利用停止リクエストを送信中です" />
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Load && (
          <div>
            <h3>検索中</h3>
            <Common.LoadingScreen text="読み込み中" />
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Failure && (
          <div>
            <h3 style={{ color: "red" }}>取得失敗</h3>
            <p>お問い合わせ番号またはPINに誤りがあります</p>
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Stopuse && (
          <div>
            <h3 style={{ color: "red" }}>ご利用停止中のお問い合わせ番号です</h3>
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Error && (
          <div>
            <h3 style={{ color: "red" }}>エラー</h3>
            <p>エラーが発生しました。再度検索してください。</p>
          </div>
        )}
        {/**残回数取得時にエラーレスポンスが返ってきた場合 */}
        {listingError &&
          datasetcheck === Enum.displaystatus.Success &&
          !stopRequestDone && (
            <div>
              <h4 style={{ color: "red" }}>読み込み中にエラーが発生しました</h4>
              <p>エラーコードを確認の上再度検索してください</p>
              <SInfoContainer>
                <span
                  style={{
                    color: "gray",
                    marginRight: "10px",
                    verticalAlign: "bottom",
                  }}
                >
                  検索内容
                </span>
                <SInfoItem>
                  <div style={{ color: "gray", marginRight: "10px" }}>
                    お問い合わせ番号
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {searchTerm_contactNumber_input}
                  </div>
                </SInfoItem>
                <SInfoItem>
                  <div style={{ color: "gray", marginRight: "10px" }}>PIN</div>
                  <div style={{ fontWeight: "bold" }}>
                    {searchTerm_pin_input}
                  </div>
                </SInfoItem>
              </SInfoContainer>
              <ServiceTable>
                <thead>
                  <tr>
                    <th>サービス名</th>
                    <th>カード番号</th>
                    <th>残高</th>
                  </tr>
                </thead>
                <tbody>
                  {services_status
                    .slice(0, listingError[0].index + 1)
                    .map((service, index) => (
                      <React.Fragment key={index}>
                        {service.jre === false && (
                          <tr>
                            <td>{service.services_name}</td>
                            <td>{servicesUsed[index].card_number}</td>
                            <td>
                              {index === listingError[0].index && (
                                <CountContainer>
                                  <CountTextContainer color="red">
                                    {"エラーコード " +
                                      listingError[0].errorCode}
                                  </CountTextContainer>
                                  <StyledIcon />
                                </CountContainer>
                              )}
                              {index !== listingError[0].index &&
                                services_count_api[index] === true && (
                                  <CountContainer>
                                    <CountTextContainer>
                                      {countlist[index]}
                                    </CountTextContainer>
                                    {/*<StyledIcon color="white" />*/}
                                  </CountContainer>
                                )}
                              {index !== listingError[0].index &&
                                services_count_api[index] === false && (
                                  <CountContainer>
                                    <CountTextContainer color="red">
                                      {countlist[index]}
                                    </CountTextContainer>
                                    <StyledIcon />
                                  </CountContainer>
                                )}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </ServiceTable>
            </div>
          )}
        {/*読み取りは成功しているが利用停止状況が一様ではない場合 */}
        {!listingError &&
          stoppedCard &&
          datasetcheck === Enum.displaystatus.Success &&
          !stopRequestDone && (
            <div>
              <h3 style={{ color: "red" }}>
                既に利用停止済みのサービスが存在します
              </h3>
              <p>
                一括利用停止を完了するには以下の再実行ボタンを押してください
              </p>
              <SInfoContainer>
                <span
                  style={{
                    color: "gray",
                    marginRight: "10px",
                    verticalAlign: "bottom",
                  }}
                >
                  検索内容
                </span>
                <SInfoItem>
                  <div style={{ color: "gray", marginRight: "10px" }}>
                    お問い合わせ番号
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {searchTerm_contactNumber_input}
                  </div>
                </SInfoItem>
                <SInfoItem>
                  <div style={{ color: "gray", marginRight: "10px" }}>PIN</div>
                  <div style={{ fontWeight: "bold" }}>
                    {searchTerm_pin_input}
                  </div>
                </SInfoItem>
              </SInfoContainer>
              <ServiceTable>
                <thead>
                  <tr>
                    <th>サービス名</th>
                    <th>カード番号</th>
                    <th>残高</th>
                    <th>稼働状況</th>
                  </tr>
                </thead>
                <tbody>
                  {services_status.map((service, index) => (
                    <React.Fragment key={index}>
                      {service.jre === false && (
                        <tr>
                          <td>{service.services_name}</td>
                          <td>{servicesUsed[index].card_number}</td>
                          <td>
                            {services_count_api[index] === true && (
                              <CountContainer>
                                <CountTextContainer>
                                  {countlist[index]}
                                </CountTextContainer>
                                {/*<StyledIcon color="white" />*/}
                              </CountContainer>
                            )}
                            {services_count_api[index] === false && (
                              <CountContainer>
                                <CountTextContainer color="red">
                                  {countlist[index]}
                                </CountTextContainer>
                                <StyledIcon />
                              </CountContainer>
                            )}
                          </td>
                          <td>
                            {errorCodeArray[index] === "***" ? "稼働" : "停止"}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </ServiceTable>
              <ButtonContainer onClick={stop_use}>
                利用停止リクエストの再実行
              </ButtonContainer>
            </div>
          )}

        {datasetcheck === Enum.displaystatus.Success &&
          stopRequestDone === "success" && (
            <h3>停止リクエストの送信に成功しました</h3>
          )}
        {datasetcheck === Enum.displaystatus.Success &&
          stopRequestDone === "failed" && (
            <h3>
              停止リクエストの送信に失敗しました。再度検索ボタンを押しステータスを確認してください
            </h3>
          )}
      </SContent>
    </div>
  );
};

export default CustomerPage;
