import * as React from "react";
import { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import AuthContext from "../provider/AuthContext";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import CircularIndeterminate from "../components/CircularIndeterminate";
import styled, { keyframes } from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from "@mui/material";

const defaultTheme = createTheme();
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Viewport Height
`;

const Navbar = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
  border-bottom: 2px solid #000;
`;

const NavItem = styled.a`
  margin-right: 20px;
  text-decoration: none;
  color: #000;
  position: relative;
  font-weight: normal;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: background-color 0.3s;
  }

  &:hover {
    font-weight: bold;
  }

  &:hover::after {
    background-color: #f00;
  }
`;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh; // 高さを制限
  width: 30%; // 横幅をさらに制限
  margin: 80px auto; // 中央揃え
  background-color: #f2f2f2; // 背景色を薄い灰色に
  padding-bottom: 50px;
`;

const SLogo = styled.img`
  width: 100px; // ロゴのサイズを調整
  margin-bottom: 50px; // 下に余白を追加
`;

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; // 必要に応じて調整
`;

const SFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%; // 横幅を調整
`;

const SLabel = styled.label`
  font-size: 0.7em; // 文字サイズを小さくする
  margin-bottom: 3px; // 下側のマージンを設定
  font-weight: bold; // 文字を太字にする
  color: #2b2929; // 色を#2B2929にする
`;

const SInput = styled.input`
  margin: 0 0 20px 0; // 上側のマージンを0に設定
  padding: 10px;
  width: 100%; // 横幅を調整
  border-radius: 6px; // 角を少し丸くする
  border: 0.5px solid #a9a9a9; // 境界線を灰色にする
  box-sizing: border-box;
`;

const SButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #006600;
  border: none;
  cursor: pointer;
  width: 60%; // 横幅を調整
  border-radius: 5px; // 角を丸くする
  font-weight: bold; // 文字を太字にする
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const SAlert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #f44336; // 背景色を赤に設定
  color: white; // 文字色を白に設定
  text-align: center;
  z-index: 9999; // 他の要素の上に表示
  animation: ${slideDown} 0.5s ease; // アニメーションを適用
`;

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    setError(null);
    event.preventDefault();
    try {
      const body = { username: username, password: password };
      const response = await axios.post(
        "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/signin",
        body,
        { headers: { "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN" } }
      );
      if (response.data.statusCode === 200) {
        setIsLoading(false);
        setAuth({ isAuth: true, username });
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("isLoggedIn", "true");
        navigate("/admin");
      } else {
        setIsLoading(false);
        throw new Error("ログインに失敗しました。");
      }
    } catch (error) {
      setIsLoading(false);
      //console.error(error);
      setError("ログインに失敗しました。");
    }
  };
  return (
    <>
      <title>管理画面ログイン</title>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <CenteredDiv>
          <CircularProgress color="success" size={100} />
        </CenteredDiv>
      ) : (
        <SContainer>
          <SLogo src="/images/シグネチャー_緑.png" />
          <SForm onSubmit={handleSubmit}>
            <SFieldContainer>
              <SLabel>ユーザー名</SLabel>
              <SInput
                type="text"
                placeholder="ユーザー名"
                onChange={(e) => setUsername(e.target.value)}
              />
            </SFieldContainer>
            <SFieldContainer>
              <SLabel>パスワード</SLabel>
              <SInput
                type="password"
                placeholder="パスワード"
                onChange={(e) => setPassword(e.target.value)}
              />
            </SFieldContainer>
            <SButton type="submit">ログイン</SButton>
          </SForm>
        </SContainer>
      )}
    </>
  );
}
