export const Enum = {
    displaystatus:{
        Success:'Success',
        Failure:'Failure',
        Stopuse:'Stopuse',
        Error:'Error',
        Load:'Load',
        DeleteLoad:'DeleteLoad',
        requestLoad:'requestLoad',
        batchrequestfinish:'batchrequestfinish',
    },
    valid_state:{
        valid:'valid',
        invalid:'invalid',
        not_exists:'not_exists',
    },
    Page:{
        admin:'/admin',
        batchhistory:'/batchhistory',
        batchProcessingScreen:'/batchProcessingScreen',
        STATION_BOOTH:'/:randomValue/STATION_BOOTH',
        JRE_MALL:'/:randomValue/JRE_MALL',
        JRE_PASSPORT:'/:randomValue/JRE_PASSPORT',
        None:'/',
        HomePage:'/:randomValue',
        About:'*',
    }
}