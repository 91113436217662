import React from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'


const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1vw solid green;
  background-color: white;
  color: green;
  padding: 2vw 5vw;
  font-size: 1em;
  cursor: pointer;
  margin: 0 auto;
  width: 60%;
  font-size: ${props => props.fontSize || '3vw'};
`;

export default function BackButoon() {
  let navigate = useNavigate();

  function handleClick() {
    let currentUrl = window.location.pathname;
    let newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
    navigate(newUrl);
  }

  return (
    <BackButton onClick={handleClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <span style={{textAlign: 'center', flexGrow: 1, fontWeight: 'bold'}}>サービス券選択に戻る</span> 
    </BackButton>
  );
}
