import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUser, FaCaretDown } from "react-icons/fa";
import { IconContext } from "react-icons";

const Logo = styled.img`
  height: 80%;
  filter: brightness(70%);
`;

const VerticalBar = styled.span`
  border-left: 1px solid #006600;
  height: 100%;
  margin: 0 20px 0 0; // 上下マージン0、左マージン0、右マージン20px
`;
const LogoutButton = styled.button`
  display: ${(props) =>
    props.isOpen
      ? "block"
      : "none"}; // isOpenプロパティに基づいて表示を切り替え
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% + 20px); // 幅を100% + 40pxに設定
  height: 100%;
  background-color: #005500; // 背景色を緑色に設定
  color: #ffffff; // 文字色を白に設定
  font-weight: bold; // 文字を太字に設定
  border: none; // 枠線を削除
  cursor: pointer;
`;
const UserMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative; // 追加: ログアウトボタンの位置を制御するため
  height: 100%; // 追加: GreenBandの高さに合わせる
`;

const UserName = styled.span`
  margin-left: 10px;
  margin-right: 5px;
  color: #006600;
`;

const Header = styled.header`
  width: 100%;
  margin-bottom: 50px;
`;

const GreenBand = styled.div`
  background-color: #e0f4e0; // 薄い緑色
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 60px;
  padding-left: 80px;
  padding-right: 20px;
`;

const Navbar = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  border-bottom: 5px solid #dddddd;
`;

const NavItem = styled(NavLink)`
  margin-right: 30px;
  text-decoration: none;
  color: #dddddd;
  position: relative;
  font-weight: normal;
  padding-bottom: 5px;
  font-weight: bold;

  &::after {
    content: "";
    display: block;
    width: calc(100% + 30px); // 下線の幅を増やして隣のNavItemと接続
    height: 5px;
    background: #dddddd;
    position: absolute;
    bottom: -5px;
    left: -15px; // 下線の位置を左にずら
  }

  &.active {
    color: #2b2929;

    &::after {
      background-color: #007700;
    }
  }
`;

const HeaderLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("isLoggedIn");

    navigate("/login", { replace: true });
  };
  return (
    <Header>
      <GreenBand>
        <Logo src="/images/シグネチャー_緑.png" alt="Logo" />
        <UserMenu onClick={() => setIsOpen(!isOpen)}>
          <VerticalBar />
          <IconContext.Provider value={{ color: "#006600" }}>
            <FaUser />
          </IconContext.Provider>
          <UserName>{sessionStorage.getItem("username")}</UserName>
          <IconContext.Provider value={{ color: "#006600" }}>
            <FaCaretDown />
          </IconContext.Provider>
          <LogoutButton isOpen={isOpen} onClick={handleLogout}>
            ログアウト
          </LogoutButton>
        </UserMenu>
      </GreenBand>
      <Navbar>
        <NavItem to="/admin" activeClassName="active">
          管理画面
        </NavItem>
        <NavItem to="/batchProcessingScreen" activeClassName="active">
          バッチ処理
        </NavItem>
      </Navbar>
    </Header>
  );
};

export default HeaderLayout;
