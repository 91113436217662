import React, { createContext, useState } from "react";

const AuthContext = createContext();

// Create a provider which holds the state and function to update it
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ isAuth: false, username: "" });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the context so it can be imported in other components
export default AuthContext;
