import React from "react";

export const Page404 = () => {
  return (
    <>
      <h1>404 NOT FOUND</h1>
      <p>お探しのページは見つかりませんでした。</p>
    </>
  );
};
