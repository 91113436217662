const services_used = [
  {
    services_name: "JRE パスポート",
    services_code: "SA12345678A2",
    services_url: "JRE_PASSPORT",
    status: true,
    count: 3,
    description:
      "JR東日本のサブスクサービス「JRE パスポート」の株主さま専用プランをご利用いただけます。",
    image_name: "ServiceLogo/01_JREPASSPORT_Logo.png",
    image_height: "18vw",
    jre: true,
    shopcode: "000",
    terminalcode: "000",
  },
  {
    services_name: "JRE MALL",
    services_code: "SA12345678A1",
    services_url: "JRE_MALL",
    status: true,
    count: 3,
    description:
      "JR東日本直営の通販サイトで1,000円以上お買い上げでご利用可能な500円OFFクーポンです。",
    image_name: "ServiceLogo/02_JREMALL_Logo.jpg",
    image_height: "7vw",
    jre: true,
    shopcode: "000",
    terminalcode: "000",
  },
  {
    services_name: "宿泊割引",
    services_code: "SA12345678A3",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_3/?param=",
    status: true,
    count: 6,
    description: "対象のホテルを株主さま向け優待価格でご利用いただけます。",
    image_name: "ServiceLogo/03_Hotel_Logo.png",
    image_height: "10vw",
    jre: false,
    shopcode: "001",
    terminalcode: "001",
  },
  {
    services_name: "レストラン・バー​",
    services_code: "SA12345678A4",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_4/?param=",
    status: true,
    count: 3,
    description:
      "対象のレストラン・バーを正規料金から10%割引でご利用いただけます。",
    image_name: "ServiceLogo/04_RestaurantBar_Logo.png",
    image_height: "10vw",
    jre: false,
    shopcode: "006",
    terminalcode: "006",
  },
  {
    services_name: "駅レンタカー",
    services_code: "SA12345678A5",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_5/?param=",
    status: true,
    count: 3,
    description:
      "JR東日本エリア内の対象の営業所で、駅レンタカーを基本料金に安心の補償もついて30%割引でご利用いただけます。",
    image_name: "ServiceLogo/05_Rentalcar_Logo.jpg",
    image_height: "15vw",
    jre: false,
    shopcode: "002",
    terminalcode: "002",
    verpad: "0%",
  },
  {
    services_name: "STATION BOOTH",
    services_code: "SA12345678A6",
    services_url: "STATION_BOOTH",
    status: true,
    count: 3,
    description: "最大1時間まで無料でご利用いただけます。",
    image_name: "ServiceLogo/06_StaionBooth_Logo.png",
    image_height: "15vw",
    jre: true,
    shopcode: "000",
    terminalcode: "000",
  },
  {
    services_name: "いろり庵きらく・そばいち",
    services_code: "SA12345678A8",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_8/?param=",
    status: true,
    count: 3,
    description:
      "わかめ・かき揚げの2種類からトッピング一品を無料サービスいたします。",
    image_name: "ServiceLogo/08_Iroriankiraku_Sobaiti.png",
    image_height: "15vw",
    jre: false,
    shopcode: "004",
    terminalcode: "004",
  },
  {
    services_name: "BECK'S COFFEE SHOP",
    services_code: "SA12345678A7",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_7/?param=",
    status: true,
    count: 3,
    description: "ドリンクを100円割引で購入することができます。",
    image_name: "ServiceLogo/07_Becks_Logo.jpg",
    image_height: "13vw",
    jre: false,
    shopcode: "003",
    terminalcode: "003",
    verpad: "4%",
    horpad: "3%",
  },
  {
    services_name: "リラクゼ",
    services_code: "SA12345678A9",
    services_url:
      "https://dggift-mpm.jp/coupon/jre_kabunushi_serviceticket_9/?param=",
    status: true,
    count: 3,
    description:
      "リラクゼーション等のサービスを正規料金から15%割引でご利用いただけます。",
    image_name: "ServiceLogo/09_RelaXE_Logo.jpg",
    image_height: "17vw",
    jre: false,
    shopcode: "005",
    terminalcode: "005",
    verpad: "3%",
    horpad: "3%",
  },
];

export default services_used;
