export const RequestBody = {
  countget: {
    key: "0127ae31be90d6c36d496ef46d9b3e12",
    cc1: "1c1627f3cca30577",
    cn: "7130750000000065",
    p: "3828",
    oc: "1004",
    sc: "00001",
    up: "0000000",
    kc: "088061",
    tc: "088061",
    lc: "12",
    cf: "0",
    rc: "0",
    osc: "",
    od: "20231128",
    ot: "175411",
    ar: "",
    crf: "1",
  },
  countget_2: {
    key: "51bada96e580e0e36d496ef46d9b3e12",
    cc1: "2d4d3f507f4ebe74",
    cn: "7130870000004311",
    p: "0018",
    oc: "1004",
    sc: "00001",
    up: "0000000",
    kc: "001",
    tc: "001",
    lc: "12",
    cf: "0",
    rc: "0",
    osc: "",
    od: "20231128",
    ot: "175411",
    ar: "",
    crf: "1",
  },
  stopcard: {
    key: "b107612bd8f43b2d86b8fcee57ba9b48",
    cc1: "2d4d3f507f4ebe74",
    oc: "0201",
    rs: "0001",
    lc: "18",
    ar: "",
    rm: "",
  },
};
