import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center; /* 水平方向（左右）中央揃え */
  align-items: center; /* 垂直方向（上下）中央揃え */
  // height: 100vh; /* 画面全体の高さを使う例 */
`;

const StyledImg = styled.img`
  width: ${props => (props.width || "100%")};
  height: auto;
`;

const QRCodeGenerator = ({ url, format, width }) => {
  const [qrCodeDataURL, setQRCodeDataURL] = useState('');

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        // QRコードのデータURLを生成
        const dataURL = await QRCode.toDataURL(url, { type: format, scale: 8 }); // scaleを変更

        // QRコードのデータURLをセット
        setQRCodeDataURL(dataURL);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    generateQRCode();
  }, [url, format]);

  return (
    <StyledContainer>
      <StyledImg width={width} src={qrCodeDataURL} alt="QR Code" />
    </StyledContainer>
  );
};

export default QRCodeGenerator;
