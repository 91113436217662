import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Papa from "papaparse";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import HeaderLayout from "../components/HeaderLayout";
import { SlArrowRight } from "react-icons/sl";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SContent = styled.div`
  text-align: left;
  padding-left: 100px;
  padding-right: 100px;
`;

const SBar = styled.div`
  width: 100%; // 横幅を100%に設定
  height: 2px; // 高さを2pxに設定
  background-color: #dddddd; // 色を#ddddddに設定
`;

const ServiceTable = styled.table`
  width: 70%;
  border-collapse: collapse;
  text-align: center;
  //background-color: #f2f2f2; // テーブルの背景色を#f2f2f2に設定
  margin-bottom: 30px;

  th,
  td {
    font-size: 18px;
    padding: 8px;
    border-bottom: 1px solid #ddd; // 各行の下に灰色の横棒
    border-left: none; // 縦棒を削除
    border-right: none; // 縦棒を削除
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    border-bottom: 2px solid #008000; // ヘッダの下に緑の横棒
    color: #008000; // テーブルヘッダの文字色を緑に設定
  }

  tbody {
    background-color: #f7f7f7; // テーブルbodyの背景色を#f2f2f2に設定
  }
`;

const BatchProcessingScreen = () => {
  const [file, setFile] = useState(null);
  const [sendable, setSendable] = useState(false);
  const [batchHistory, setBatchHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDetail, setIsDetail] = useState(null);
  const [fileName, setFileName] = useState(null);
  const itemsPerPage = 10;
  const fileInput = useRef(null);
  const itemsDetailPerPage = 10;

  const [batchData, setBatchData] = useState(null);
  const [currentDetailPage, setCurrentDetailPage] = useState(
    parseInt(sessionStorage.getItem("currentDetailPage")) || 1
  );

  const handleBackHistory = () => {
    setIsDetail(null);
    setBatchData(null);
    setCurrentDetailPage(1);
  };
  const handleFileChange = async (event) => {
    if (event.target.files[0] === undefined) {
      return;
    }

    setFileName(event.target.files[0].name);

    Papa.parse(event.target.files[0], {
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data;
        const length = data.length;
        let canSend = true;

        for (let i = 1; i < length; i++) {
          if (
            !(/^[0-9]{16}$/.test(data[i][0]) && /^[0-9]{4}$/.test(data[i][1]))
          ) {
            window.alert("入力されたデータに問題があります");
            fileInput.current.value = "";
            canSend = false;
            break;
          }
        }
        setFile(data);
        setSendable(canSend);
      },
    });
  };

  const handleClearReservation = async () => {
    const willClear = window.confirm("予約されているバッチを取り消しますか？");
    if (willClear) {
      try {
        const res_clear = await axios.get(
          "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/clearReservation",
          {
            headers: {
              "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN",
            },
          }
        );
        window.alert(res_clear.data.message);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDetailClick = async (csvFileName) => {
    setIsDetail(csvFileName);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/batchdetail",
          {
            params: { csvFileName: csvFileName },
            headers: {
              "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN",
            },
          }
        );
        setBatchData(response.data);
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    };

    fetchData();
  };

  const handleReserveClick = async (event) => {
    const willSend = window.confirm(`${fileName}を送信します`);
    if (willSend) {
      const requestBody = file.slice(1).map((item) => {
        return { contact_number: item[0], pin: item[1] };
      });
      try {
        document.body.style.cursor = "wait";
        const response = await axios.post(
          "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/reserve",
          requestBody,
          {
            headers: {
              "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN",
            },
          }
        );
        document.body.style.cursor = "default";
        window.alert("バッチの予約に成功しました");
        fileInput.current.value = "";
        setSendable(false);
      } catch (error) {
        document.body.style.cursor = "default";
        window.alert("バッチの予約に失敗しました");
        fileInput.current.value = "";
        setSendable(false);
        console.error("Error uploading file: ", error);
      }
    }
  };
  const formatDate = (dateString) => {
    const [yyyy, mm, dd, hh, min, ss] = dateString.split("_");
    return `${yyyy}年${mm}月${dd}日 ${hh}:${min}:${ss}`;
  };

  const pattern = /^(\d{4}_\d{2}_\d{2}_\d{2}_\d{2}_\d{2})_\d_\d+_\d+_\d+$/;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = batchHistory.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    const fetchBatchHistory = async () => {
      try {
        const response = await axios.get(
          "https://ckno6eu9lb.execute-api.ap-northeast-1.amazonaws.com/prod/batchhistory",
          {
            headers: {
              "x-api-key": "iUcmbUAlCo7PMgikY69Ub26Fd0APQ7Cd5WEINZTN",
            },
          }
        );
        setBatchHistory(response.data);
        //setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchBatchHistory();

    const currentDetailPageSession =
      sessionStorage.getItem("currentDetailPage");
    const csvFileName = sessionStorage.getItem("csvFileName");
    if (currentDetailPage && csvFileName) {
      handleDetailClick(csvFileName);
      sessionStorage.removeItem("currentDetailPage");
      sessionStorage.removeItem("csvFileName");
    }
  }, []);

  return (
    <div>
      <HeaderLayout />
      <SContent>
        <title>利用停止リクエストバッチ</title>
        <div>
          <span>
            以下のボタンからバッチ対象のCSVファイルをアップロードできます
          </span>
        </div>
        <br></br>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            marginBottom: "30px",
            //alignItems: "center",
          }}
        >
          <Button
            color="success"
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            endIcon={<CloudUploadIcon />}
          >
            ファイルのアップロード
            <VisuallyHiddenInput
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              ref={fileInput}
            />
          </Button>
          {sendable ? (
            <Button
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
              onClick={handleReserveClick}
              style={{ marginTop: "15px", marginBottom: "5px" }}
            >
              バッチを予約
            </Button>
          ) : (
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleReserveClick}
              disabled
              style={{ marginTop: "15px", marginBottom: "5px" }}
            >
              バッチを予約
            </Button>
          )}
        </div>
        <div>
          <span>以下のボタンから予約済みバッチを取り消すことができます</span>
          <div>
            <Button
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
              onClick={handleClearReservation}
              style={{ marginTop: "15px", marginBottom: "40px" }}
            >
              予約取り消し
            </Button>
          </div>
        </div>
        <SBar />
        {!isDetail && batchHistory.length !== 0 && (
          <div>
            <div
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                fontSize: "120%",
              }}
            >
              <span>バッチ履歴</span>
            </div>
            <div>
              <ServiceTable>
                <thead>
                  <tr>
                    <th>実行日時</th>
                    <th>処理結果</th>
                    <th>全件数</th>
                    <th>成功件数</th>
                    <th>失敗件数</th>
                    <th>詳細</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.executedDate)}</td>
                      <td>{item.success === "0" ? "失敗" : "成功"}</td>
                      <td>{item.total}</td>
                      <td>{item.ok}</td>
                      <td>{item.failed}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleDetailClick(item.filename)}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ServiceTable>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "100px",
                }}
              >
                <Pagination
                  page={currentPage}
                  count={Math.ceil(batchHistory.length / itemsPerPage)}
                  onChange={(e, page) => setCurrentPage(page)}
                  color="success"
                  shape="rounded"
                  size="small"
                />
              </div>
            </div>
          </div>
        )}
        {!isDetail && batchHistory.length === 0 && <h3>バッチ履歴なし</h3>}
        {isDetail && batchData && (
          <div>
            <div
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                fontSize: "120%",
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                バッチ履歴{" "}
                <SlArrowRight
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                />
                実行日時: {formatDate(isDetail.match(pattern)[1])}
              </span>
            </div>
            <ServiceTable>
              <thead>
                <tr>
                  <th>お問い合わせ番号</th>
                  <th>PIN</th>
                  <th>エラー詳細</th>
                </tr>
              </thead>
              <tbody>
                {batchData
                  .slice(
                    currentDetailPage * itemsDetailPerPage - itemsDetailPerPage,
                    currentDetailPage * itemsDetailPerPage
                  )
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.cardNumber}</td>
                      <td>{item.pin}</td>
                      <td>
                        {item.error === "OK" ? (
                          item.error
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "currentDetailPage",
                                  currentDetailPage
                                );
                                sessionStorage.setItem("csvFileName", isDetail);
                              }}
                              component={Link}
                              to="/admin"
                              state={{
                                data: {
                                  contactNumber: item.cardNumber,
                                  pin: item.pin,
                                },
                              }}
                            >
                              確認
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </ServiceTable>
            <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Pagination
                page={currentDetailPage}
                count={Math.ceil(batchData.length / itemsDetailPerPage)}
                onChange={(e, page) => setCurrentDetailPage(page)}
                color="success"
                shape="rounded"
                size="small"
              />
            </div>
            <div style={{ marginBottom: "100px" }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleBackHistory}
              >
                履歴一覧へ戻る
              </Button>
            </div>
          </div>
        )}
      </SContent>
    </div>
  );
};

export default BatchProcessingScreen;
