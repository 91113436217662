import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const GreenRoundButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  text-decoration: none;
  font-size: ${props => props.fontSize || '5vw'};
  border-radius: 3vw;
  padding: ${props => props.pad || '0.1vw'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  box-sizing: border-box;
  box-shadow: 0.16em 0.16em 0.2em 0em #888888;
  font-weight: bold;
`;

const LinkButton = ({ href, buttonvalue, width, height, fontSize, pad }) => {
  return (
    <GreenRoundButton href={href} width={width} height={height} fontSize={fontSize} pad={pad}>
      <span style={{textAlign: 'center', flexGrow: 1}}>{buttonvalue}</span> {/* テキストを中央に配置 */}
      <FontAwesomeIcon icon={faChevronRight} />
    </GreenRoundButton>
  );
};

export default LinkButton;
