import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Common from "../components/index";
import services_status from "../lib/ServiceStatus";
import { RegularExpression } from "../lib/RegularExpression";
import { GetShareholder, GetCount } from "../lib/Api";
import { Enum } from "../lib/Enum";
import { RequestBody } from "../lib/RequestBody";
import generateRandomString from "../lib/util";

const Couponcodetext = styled.p`
  width: 75vw;
  height: 7vw;
  box-sizing: border-box;
  padding: 5%;
  border: none;
  color: #2b2929;
  font-size: 7vw;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const Text = styled.p`
  color: green;
  font-weight: bold;
  text-align: center;
  font-size: 4.5vw;
  margin-bottom: 2vw;
`;

const Remark = styled.div`
  background: white;
  margin: 0 auto;
  border-radius: 10px;
  border: none;
  padding: 5%;
  width: 70%;
  white-space: pre-wrap;
  text-align: left;
  font-size: clamp(3px, 3.5vw, ${(props) => props.textsize || "24px"});
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 20px;
`;

const ListItem = styled.li`
  //margin-bottom: 10px;
  &:before {
    content: "〇";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const JRE_MALLPage = () => {
  const Service_data = services_status[1];
  const [datasetcheck, setdatasetcheck] = useState(Enum.displaystatus.Load);
  const [uniqueCode, setUniqueCode] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentURL = window.location.href;
        const pattern = RegularExpression.UniqueURL;
        const match = currentURL.match(pattern);
        const resultString = match[1].replace("/JRE_MALL", "");
        const response = GetShareholder(resultString);
        return response;
      } catch (error) {
        setdatasetcheck(Enum.displaystatus.Error);
        return 0;
      }
    };

    const checkIsStop = async (card_number, pin) => {
      try {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedTime = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}${currentDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;

        const key = "b107612bd8f43b2d86b8fcee57ba9b48";
        const cc1 = "2d4d3f507f4ebe74";
        const cn = card_number;
        const p = pin;
        const sc = generateRandomString();
        const { oc, up, kc, tc, lc, cf, rc, osc, ar, crf } =
          RequestBody.countget_2;

        const Body = `key=${key}&cc1=${cc1}&cn=${cn}&p=${p}&oc=${oc}&sc=${sc}&up=${up}&kc=${kc}&tc=${tc}&lc=${lc}&cf=${cf}&rc=${rc}&osc=${osc}&od=${formattedDate}&ot=${formattedTime}&ar=${ar}&crf=${crf}`;

        const response = await GetCount(Body);
        const response_match = response.data
          .match(RegularExpression.responsePattern)[1]
          .match(RegularExpression.dataPattern);
        return response_match;
      } catch (error) {
        // エラーハンドリング
        console.error("Error fetching data:", error);
        throw error; // エラーを呼び出し元に投げる
      }
    };

    const fetchDataAndProcess = async () => {
      const Shareholder = await fetchData();
      const Shareholder_Array = JSON.parse(Shareholder.data.body);
      //console.log(Shareholder_Array[0].status);
      if (Shareholder_Array[0] === undefined) {
        setdatasetcheck(Enum.displaystatus.Failure);
        return;
      }
      const isStop = await checkIsStop(
        Shareholder_Array[0].contact_number,
        Shareholder_Array[0].pin
      );
      if (isStop[1] === "G61") {
        setdatasetcheck(Enum.displaystatus.Stopuse);
        return;
      }
      setdatasetcheck(Enum.displaystatus.Success);
      setUniqueCode(
        Shareholder_Array[0].uniqueCode
        //.replace(/-/g, "")
        //.match(/.{1,4}/g)
        //.join(" ")
      );
    };

    fetchDataAndProcess();
  }, []);

  return (
    <>
      <Common.BasePage
        header={
          <Common.PageHeader
            imagepath={"/images/ServiceLogo/02_JREMALL_Logo.jpg"}
            headertitle={"JRE MALL"}
            width={"80%"}
            imageheight={"30vw"}
            imgh={"75%"}
          />
        }
        backButton={true}
      >
        <title>JRE MALL</title>
        {datasetcheck === Enum.displaystatus.Success && (
          <div>
            <Common.Blamk margin="15%" />
            {/*<Common.Branch
              datasetcheck={datasetcheck}
              status={Service_data.status}
        >*/}
            <Text>クーポンコード</Text>
            <Common.Blamk margin="2vw" />
            <Couponcodetext>{uniqueCode}</Couponcodetext>
            <Common.Blamk margin="9vw" />
            <Common.LinkButton
              href="https://www.jreastmall.com/shop/?utm_source=jreast.co.jp&utm_medium=referral&utm_campaign=20240621_kabunushi"
              buttonvalue="JRE MALLショッピングはこちら"
              width="75vw"
              height="15vw"
              fontSize="3.8vw"
              pad="2vw"
            />
            <Common.Blamk margin="9vw" />
            <Remark>
              <List>
                <ListItem>
                  クーポンのご利用にはJRE MALL会員への登録が必要です。
                </ListItem>
                <ListItem>
                  会員ログインのうえ、注文時にクーポンコードを入力してご利用ください。
                </ListItem>
                <ListItem>有効期限は2025年6月30日までです。</ListItem>
                <ListItem>
                  1会計につき、合計金額（送料除く）が1,000円（税込）以上のご購入でご利用いただけます。
                </ListItem>
                <ListItem>ご利用は1回限りです。</ListItem>
                <ListItem>
                  JRE MALLショッピング内の電子チケットやネットでエキナカ、JRE
                  MALLふるさと納税、JRE
                  MALLチケットなどご利用いただけない商品やサービスがございます。
                </ListItem>
                <ListItem>現金との引換はいたしません。</ListItem>
                <ListItem>
                  返品・取り消しがあった場合、クーポンのお戻しはできませんのでご注意ください。
                </ListItem>
                <ListItem>他のクーポンとの併用はできません。</ListItem>
                <ListItem>
                  2024年5月時点の情報です。クーポンのご利用方法は変更になる可能性がございます。最新情報はご利用時のガイダンスをご確認ください。
                </ListItem>
              </List>
              ＜クーポンのご利用に関するお問い合わせ＞<br></br>
              【JRE MALLサポートセンター】<br></br>
              &nbsp;&nbsp;03-3514-8020<br></br>
              &nbsp;&nbsp;受付時間：10時～18時
            </Remark>
            {/*</Common.Branch>*/}
          </div>
        )}
        <Common.Blamk margin="5%" />
        {datasetcheck === Enum.displaystatus.Stopuse && (
          <div>
            <h3>サービス停止中</h3>
            <p>ご利用停止中のコードです</p>
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Load && (
          <div>
            <Common.LoadingScreen text="読み込み中" />
          </div>
        )}
        {datasetcheck === Enum.displaystatus.Failure && (
          <div>
            <h3>読み込み失敗</h3>
            <p>発行されていないURLです</p>
          </div>
        )}
      </Common.BasePage>
    </>
  );
};

export default JRE_MALLPage;
