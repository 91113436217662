import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      navigate("/admin", { replace: true }); // ログイン済みの場合は/adminにリダイレクト
    }
  }, []);

  return children;
};

export default LoginRoute;
